import Retool from 'react-retool';

function App() {
  return (
    <div class="app">
      <Retool url="https://primocare.retool.com/embedded/public/0d72336c-f326-4ac3-831c-2e3aeb03b709"/>
    </div>
  );
}

export default App;
